/* eslint-disable */

const locale_translations = {
    /*
  'es': {
    'hello': 'hola'
  }
  */
}

export var set = function (translations) {
    // we could just assign locale_translations = translations, but
    // I would like to keep locale_translations as a const,
    // therefore set each set of translations manually
    Object.keys(translations).forEach(function (locale) {
        locale_translations[locale] = translations[locale]
    })
}

function getOldChoiceIndexFixed(choice) {
    return choice ? 1 : 0
}

function getChoiceIndex(choice, choicesLength) {
    choice = Math.abs(choice)

    if (choicesLength === 2) {
        return getOldChoiceIndexFixed(choice)
    }

    return choice ? Math.min(choice, 2) : 0
}

function getRuPlChoiceIndex(choice, choicesLength) {
    if (choice === 0) {
        return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1

    if (!teen && endsWithOne) {
        return 1
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
    }

    return choicesLength < 4 ? 2 : 3
}

export function fetchChoice(message, choice, locale) {
    if (!message && typeof message !== 'string') {
        return null
    }
    let choices = message.split('|')
    if (locale === 'ru' || locale === 'pl') {
        choice = getRuPlChoiceIndex(choice, choices.length)
    } else {
        choice = getChoiceIndex(choice, choices.length)
    }
    if (!choices[choice]) {
        return message
    }
    return choices[choice].trim()
}

export var fetchTranslation = function (locale, key, choice) {
    if (!locale) return key

    var translations = locale_translations[locale]

    if (translations && key in translations) {
        if (choice !== null) {
            return fetchChoice(translations[key], choice, locale)
        }
        return translations[key]
    }

    // key not found, fall back from dialect translations

    // if (locale.indexOf('_') > -1) {
    //     return fetch(locale.substr(0, locale.indexOf('_')), key)
    // }
    //
    // if (locale.indexOf('-') > -1) {
    //     return fetch(locale.substr(0, locale.indexOf('-')), key)
    // }

    // key does not exist

    if (
        translations &&
        import.meta.env.VITE_POEDIOR_KEY &&
        import.meta.env.VITE_POEDITOR_PROJECT
    ) {
        sendToPoeEditor(locale, key)
        console.info(`[vue-i18n]${locale}`, key)
    }

    if (choice !== null) {
        return fetchChoice(key, choice, locale)
    }
    return key
}

function sendToPoeEditor(locale, key) {
    var serialize = function (data) {
        return Object.keys(data)
            .map(function (keyName) {
                return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
            })
            .join('&')
    }
    // test for alpha numerical to prevent jp strings from poeditor
    if (!key.match(/^[0-9a-z\s!,/.!@#$%^&*\{\}\[\]\|\(\)-_\+\"]+$/i)) {
        console.info(`[vue-i18n] skipping `, key)
        return
    }
    let api_token = import.meta.env.VITE_POEDIOR_KEY
    let id = import.meta.env.VITE_POEDITOR_PROJECT
    let data = [
        {
            term: key,
            context: '',
            reference: window?.location.href,
        },
    ]
    let url = `https://api.poeditor.com/v2/terms/add`

    fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method: 'POST',
        mode: 'no-cors',
        body: serialize({ data: JSON.stringify(data), api_token, id }),
    }).catch(e => console.warn(e))
}
